import React from 'react';
import { Layout } from '../components/common';
import { Register, DonateDetails } from '../components/home';

const DonatePage = ({location}) => (
  <Layout
    seo={{
      title: 'Adományozás',
      lang: 'hu',
      description:
        'Szeptemberben startol a Hungarian Startup University Program',
    }}
    baseRoute="/"
    location={location}
  >
    <DonateDetails />
    <Register />
  </Layout>
);

export default DonatePage;
